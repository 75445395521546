<template>
  <div class="questionBank">
    <div class="room" v-if="list && list.length > 0">
      <div class="item-box" v-for="(item, index) in list" :key="index" @click="tabId = item.id">
        <div class="item">
          <img class="item-image" src="@/assets/img/homesixth/Group 3684.png" alt="" />
          <div class="item-content">
            <div class="item-title twoEllipsis">
              <!-- <span class="title-type">{{ mTypeEnum[item.type] }}</span> -->
              {{ item.name }}
            </div>
            <div class="item-num">
              {{ item.roomPaperSize }}套试卷 / {{ item.kindName }}-{{ item.majorName }}
            </div>
            <!-- <div class="item-tips">{{ item.roomPaperSize }}套试卷</div> -->
            <div class="item-tips">
              {{ item.startTime }}-{{ item.endTime }}
            </div>
          </div>
          <div v-if="(item.roomStatus == 3)" class="keep-learns keep-ok" @click="goRoom(item)">
            进入考场
          </div>
          <div v-else-if="(item.type == 1 || item.showStatus == 1) && (item.roomStatus == 2 || item.roomStatus == 3)"
            class="keep-learns keep-check" @click="goRoom(item)">
            进入考场
          </div>
          <div v-else-if="item.roomStatus == 1 || item.type == 2" class="keep-learns keep-ok" @click="expired(item)">
            进入考场
          </div>
        </div>
      </div>
    </div>
    <div class="nothing" v-if="!list || list.length == 0">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无内容哦~</div>
    </div>
    <el-pagination class="pagination" :total="total" :current-page="pageNum" :page-sizes="[8, 16, 24, 40]"
      :page-size="pageSize" :pager-count="5" @current-change="handleCurrentChange" @size-change="handleSizeChange"
      background layout="total, sizes, prev, pager, next">
    </el-pagination>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { getInfo } from "@/api/cookies";
import { AnswerClient } from "@/api/task/answerClient";
let answerClient = new AnswerClient();
import { roomType } from "@/api/emun";
export default {
  components: {},

  data() {
    return {
      mTypeEnum: roomType,
      list: [], //查询列表
      getInfo: null,
      pageSize: 8,
      pageNum: 1,
      total: 0,
    };
  },
  async created() {
    this.userInfo = getInfo();
    this.getList();
  },
  methods: {
    /* 获取列表 */
    getList() {
      /* 虚拟考场 */
      answerClient
        .getPcMyRoom(
          this.pageSize,
          this.pageNum,
          undefined,
          undefined,
          this.userInfo ? this.userInfo.id : undefined
        )
        .then((res) => {
          this.list = res.rows;
          this.total = res.total;
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    goRoom(item) {
      let sub = {
        roomId: item.id,
        classId: item.classId,
      };
      let data = Vue.prototype.export(sub);
      this.$router.push(`/quesTion/examRoom/examRoomDetail?${data}`);
    },
    expired(item) {

      if (item.roomStatus == 1) {
        this.$message({
          type: "warning",
          message: "考场未开始",
        });
      } else if (item.roomStatus == 3) {
        this.$message({
          type: "warning",
          message: "考场已结束",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.questionBank {
  background: #ffffff;
  padding-bottom: 20px;
}

/* 虚拟考场 */
.room {
  width: 100%;
  padding: 0px 0px 20px;

  .item-box {
    .item {
      width: 100%;
      display: flex;
      background: #ffffff;
      border-radius: 16px 16px 16px 16px;
      padding: 24px;
      margin-bottom: 0px;
      border-bottom: 1px solid #eeeeee;
      position: relative;
      height: 106px;
      padding-top: 24px;
      align-items: start;

      .keep-learns {
        width: 80px;
        height: 28px;
        background: #ffffff;
        border-radius: 14px 14px 14px 14px;
        opacity: 1;
        border: 1px solid #1061ff;
        text-align: center;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #1061ff;
        line-height: 26px;
        margin-top: 15px;
        cursor: pointer;
      }

      .item-image {
        width: 70px;
        height: 81px;
        flex-shrink: 0;
        background: #ffffff;
        // margin-right: 12px;
      }

      .item-content {
        width: 100%;
        padding-right: 30px;

        .item-title {
          font-size: 16px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #333333;

          .title-type {
            height: 16px;
            background: #ffeee2;
            border-radius: 2px;
            font-size: 11px;
            font-family: PingFang SC-Regular;
            font-weight: 400;
            color: #ff7b1c;
            line-height: 11px;
            line-height: 16px;
            display: inline-block;
            margin-right: 8px;
            padding: 0px 6px;
          }
        }

        .item-num {
          font-size: 14px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #999999;
          line-height: 14px;
          margin-top: 12px;
        }

        .item-tips {
          font-size: 12px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #999999;
          line-height: 12px;
          margin-top: 12px;
        }
      }
    }
  }
}

/* 按钮 */
.keep-learn {
  height: 30px;
  line-height: 28px;
  padding: 0px 16px;
  position: absolute;
  right: 32px;
  flex-shrink: 0;
}

/deep/ .el-pagination .active {
  background: #1061ff !important;
}

.keep-ok {
  border: 1px solid #666 !important;
  color: #666 !important;
}
</style>
